<template>
  <div class="insufficient">
    <el-dialog
    v-model="dialogVisible"
    width="382px"
    :before-close="handleClose"
    center
    >
      <div class="insuf_cont">
        <div class="insuf_cont_tit flex_row_center_center">
          <img src="@/assets/ze-warning.png" alt="">
          <p>温馨提示</p>
        </div>
        <div class="insuf_cont_ti">
          非常抱歉，您提报的需求重量超出网站可供重量，如有需要请提交您的采购需求，我们会尽快联系您
        </div>
        <div class="insuf_cont_inp">
          <p>采购重量：</p>
          <div class="insuf_cont_inp_o">
            <input type="number" v-model="purchaseNum" @input="inpNum" name="" placeholder="请输入采购重量" id="">
            <p>吨</p>
          </div>
        </div>
        <div class="insuf_cont_inp" style="margin-top: 5px;">
          <p></p>
          <span>请填写您需要采购的产品重量</span>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button class="btns2" type="primary" @click="submits">
            提交
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, computed, watch,getCurrentInstance, reactive } from "vue";
import { ElMessage, ElRate } from "element-plus";
export default {
  props: ["goodsId"],
  setup( props, { emit } ) {
    const router = useRouter();
    const dialogVisible = ref(true);
    const purchaseNum = ref('');//数量
    const goodsInfo = reactive({
      data:{}
    })
    const { proxy } = getCurrentInstance();

    // 输入框事件
    const inpNum = ()=>{
      if(purchaseNum.value<0){
        purchaseNum.value=0
      }
      let num = String(purchaseNum.value).split('.')
      if(num[1].length>2){
        num[1] = num[1].substring(0,2)
        let nums = num.join('.')
        purchaseNum.value = Number(nums)
      }
    }

    // 确定事件
    const submits = ()=>{
      if(!purchaseNum.value){
        ElMessage('请输入采购重量')
        return
      }
      let param = {
        purchaseNum:purchaseNum.value,
        goodsId:props.goodsId
      }
      proxy.$post("v3/business/front/cart/addGoodsPurchase",param).then((res) => {
          if (res.state == 200) {
              ElMessage.success(res.msg);
              emit("Close");
          } else {
              ElMessage.error(res.msg)
          }
        }).then(() => {
            
        })
    }

    const handleClose = ()=>{
      emit("Close");
    }
    

   

    return {
      dialogVisible,
      goodsInfo,
      purchaseNum,
      inpNum,
      submits,
      handleClose

    };
  },
};
</script>

<style lang="scss">
.insufficient {
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
    -moz-appearance: textfield;
  }
  .btns2{
    margin-left: 54px;
  }
  .el-button{
    padding: 8px 20px;
    min-height: 30px;
  }
  .el-dialog__body{
    padding-top: 15px;
  }
  .insuf_cont{
    .insuf_cont_tit{
      img{
        width: 20px;
        height: 20px;
      }
      p{
        color: rgba(16, 16, 16, 1);
        font-weight: bold;
        margin-left: 10px;
      }
    }
    .insuf_cont_ti{
      text-align: center;
      color: rgba(16, 16, 16, 1);
      margin-top: 20px;
    }
    .insuf_cont_inp{
      display: grid;
      grid-template-columns: 23% auto;
      align-items: center;
      margin-left: 25px;
      margin-top: 30px;
      input{
        width: 187px;
        height: 30px;
        border-radius: 3px;
        color: rgba(136, 136, 136, 1);
        font-size: 14px;
        text-align: left;
        border: 1px solid rgba(187, 187, 187, 1);
        padding: 0 5px;
      }
      span{
        color: rgba(187, 187, 187, 1);
        font-size: 12px;
        text-align: left;
        width: 187px;
      }
      .insuf_cont_inp_o{
        display: flex;
        align-items: center;
        p{
          width: auto;
          margin-left: 10px;
        }
      }
    }
  }
}
.el-checkbox__label {
  line-height: 17px;
  font-size: 12px;
}
</style>