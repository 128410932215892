<template>
  <div class="shopcart">
    <el-dialog
      v-model="dialogVisible"
      title="加入购物车"
      width="700px"
      :before-close="handleClose"
    >
      <div class="shopcart_cont">
        <div class="shopcart_box shopcart_xiao">
          <p>销售单位</p>
          <div class="flex_row_start_center">
            <div class="goods_company_radio flex_row_start_center" v-if="goodsPropertys.meterWeight" @click="goJiaType(1)">
              <img src="@/assets/radio_xuan.png" alt="" v-if="jiaType==1"/>
              <img src="@/assets/radio_un.png" alt="" v-else />
              <span>米</span>
            </div>
            <div class="goods_company_radio flex_row_start_center" v-if="goodsPropertys.isSupportKg==1" @click="goJiaType(4)">
              <img src="@/assets/radio_xuan.png" alt="" v-if="jiaType==4"/>
              <img src="@/assets/radio_un.png" alt="" v-else />
              <span>吨</span>
            </div>
            <div class="goods_company_radio flex_row_start_center" v-if="roll&&roll.length>0" @click="goJiaType(5)">
              <img src="@/assets/radio_xuan.png" alt="" v-if="jiaType==5"/>
              <img src="@/assets/radio_un.png" alt="" v-else />
              <span>卷</span>
            </div>
            <div class="goods_company_radio flex_row_start_center" v-if="goodsPropertys.sliceWeight" @click="goJiaType(2)">
              <img src="@/assets/radio_xuan.png" alt="" v-if="jiaType==2"/>
              <img src="@/assets/radio_un.png" alt="" v-else />
              <span>片</span>
            </div>
            <div class="goods_company_radio flex_row_start_center" v-if="goodsPropertys.rootWeight" @click="goJiaType(3)">
              <img src="@/assets/radio_xuan.png" alt="" v-if="jiaType==3"/>
              <img src="@/assets/radio_un.png" alt="" v-else />
              <span>根</span>
            </div>
          </div>
        </div>
        <div class="shopcart_chang" v-if="jiaType==1">
          <div class="shopcart_box">
            <p>长&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;度</p>
            <div class="longitude_inp flex_row_start_center">
              <input type="number" placeholder="请输入长度" v-model="inpshu.chang" @input="numFour('chang')"/>
              <span>米
                 = {{(inpshu.chang*goodsPropertys.meterWeight).toFixed(3)}}吨
                </span>
            </div>
          </div>
          <div class="shopcart_box">
            <p></p>
            <p class="p1">
              需填写该规格长度的倍数，例如：选择的型材是6米长时，则需填写6米的倍数
            </p>
          </div>
        </div>
        <div class="shopcart_chang" v-if="jiaType==4">
          <div class="shopcart_box">
            <p>吨&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数</p>
            <div class="longitude_inp flex_row_start_center">
              <input type="number" placeholder="请输入吨数" v-model="inpshu.dun" @input="numFour('dun')"/>
              <span>吨</span>
            </div>
          </div>
        </div>
        <div class="shopcart_chang" v-if="jiaType==2">
          <div class="shopcart_box">
            <p>片&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数</p>
            <div class="longitude_inp flex_row_start_center">
              <input type="number" placeholder="请输入片数" v-model="inpshu.pian" @input="numFour('pian')"/>
              <span>片 
                 = {{(inpshu.pian*goodsPropertys.sliceWeight).toFixed(3)}}吨
                </span>
            </div>
          </div>
        </div>
        <div class="shopcart_chang" v-if="jiaType==3">
          <div class="shopcart_box">
            <p>根&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数</p>
            <div class="longitude_inp flex_row_start_center">
              <input type="number" placeholder="请输入根数" v-model="inpshu.gen" @input="numFour('gen')"/>
              <span>根
                 = {{(inpshu.gen*goodsPropertys.rootWeight).toFixed(3)}}吨
                </span>
            </div>
          </div>
        </div>
        <div class="shopcart_chang" v-if="jiaType==5">
          <div class="shopcart_box">
            <p>卷&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重</p>
            <div class="longitude_xuan flex_row_start_center" >
              <template v-for="(item,index) in roll"  :key="index">
                  <div class="longitude_xuan_o flex_row_center_center" :class="{'longitude_xuan_check':item.checks}"  v-if="item.rollNum" @click="longcheck(index,item.checks)">
              {{item.rollWight}}吨
              </div>
                  </template>
            </div>
          </div>
        </div>
        <div class="shopcart_jin" >
          <div class="shopcart_box">
            <p>交货方式</p>
            <div class="mode_type flex_row_start_center"  v-if="deliverType==1">
              <div class="mode_type_box flex_row_start_center">
                <img src="@/assets/cart/sel.png" alt="" v-if="goodsPropertys.deliverType==1" @click="deliverCheck(2)"/>
                <img src="@/assets/cart/no_sel.png" alt="" v-else @click="deliverCheck(1)"/>
                <span>净板交货</span>
                <span>&nbsp;&nbsp;{{goodsPropertys.deliverTypePrice}}元/吨</span>
              </div>
            </div>
            <div class="mode_type flex_row_start_center" v-else>
              <div class="mode_type_box flex_row_start_center">
                <span>带头尾交货</span>
              </div>
            </div>
          </div>
          <!-- <div class="shopcart_box" v-if="deliverType==1">
            <p></p>
            <p class="p1">系统默认为带头尾价格，若要净板请在增值服务处“√”</p>
          </div> -->
        </div>
        <div class="shopcart_box shopcart_dan">
          <p>合计单价</p>
          <div class="shopcart_danjia flex_row_start_center">
            <div>
              ￥{{total_sheet?total_sheet.toFixed(2):0}}/吨
              <!-- {{
                invalidItem.goodsPrice ? invalidItem.goodsPrice.toFixed(2) : 0
              }} -->
            </div>
            <span>（不含物流运费）</span>
          </div>
        </div>
        <div class="shopcart_box shopcart_dan">
          <p>合计金额</p>
          <div class="shopcart_danjia flex_row_start_center">
            <div>
              ￥{{total_amount?total_amount.toFixed(2):0}}
              <!-- {{
                invalidItem.goodsPrice ? invalidItem.goodsPrice.toFixed(2) : 0
              }} -->
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="subm">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, computed, watch,getCurrentInstance, reactive } from "vue";
import { ElMessage, ElRate } from "element-plus";
export default {
  props: ["cartInfo","goodsPropertys","DialogType",'deliverType' ,"categoryId", "loading",'item','roll'],
  setup( props, { emit } ) {
    const router = useRouter();
    const dialogVisible = ref(true);
    const goodsInfo = reactive({
      data:{}
    })
    const { proxy } = getCurrentInstance();
    const jiaType = ref(props.DialogType);
    const inpshu = reactive({
      chang:'',
      dun:'',
      juan:'',
      pian:'',
      gen:'',
    })
    const total_sheet = computed(() => {
        if (props.goodsPropertys.deliverType==1) {
            return props.goodsPropertys.deliverTypePrice+props.goodsPropertys.goodsPrice
        } else {
            return props.goodsPropertys.goodsPrice
        }
    })
    const total_amount = computed(() => {
        if (jiaType.value==1) {
          if (props.goodsPropertys.deliverType==1) {
            return (props.goodsPropertys.meterWeight*inpshu.chang)*total_sheet.value
          } else {
            return (props.goodsPropertys.meterWeight*inpshu.chang)*total_sheet.value
          }
          // if(inpshu.chang.length>0){
          // }else{
          //   return 0
          // }
        } else if (jiaType.value==4) {
          if (props.goodsPropertys.deliverType==1) {
            return inpshu.dun*total_sheet.value
          } else {
            return inpshu.dun*total_sheet.value
          }
          // if(inpshu.chang.length>0){
          // }else{
          //   return 0
          // }
        }else if (jiaType.value==5) {
          let juansum = 0
            props.roll.forEach(items => {
              if(items.checks){
                juansum+=Number(items.rollWight)
              }
            });
            return juansum*total_sheet.value
          // if(inpshu.chang.length>0){
          // }else{
          //   return 0
          // }
        }else if (jiaType.value==2) {
          if (props.goodsPropertys.deliverType==1) {
            return (props.goodsPropertys.sliceWeight*inpshu.pian)*total_sheet.value
          } else {
            return (props.goodsPropertys.sliceWeight*inpshu.pian)*total_sheet.value
          }
          // if(inpshu.chang.length>0){
          // }else{
          //   return 0
          // }
        }else if (jiaType.value==3) {
          if (props.goodsPropertys.deliverType==1) {
            return (props.goodsPropertys.rootWeight*inpshu.gen)*total_sheet.value
          } else {
            return (props.goodsPropertys.rootWeight*inpshu.gen)*total_sheet.value
          }
          // if(inpshu.chang.length>0){
          // }else{
          //   return 0
          // }
        }else{
          return 0
        }
    })
    // const toDetail = () => {
    //   let newWin = router.resolve({
    //     path: "/goods/series",
    //     query: {
    //       seriesId: props.item.seriesId,
    //       categoryId: props.categoryId,
    //     },
    //   });

    //   window.open(newWin.href, "_blank");
    // };

    const handleClose  = ()=>{
      emit("close");
    }
    // 选择卷重
    const longcheck = (index,check)=>{
         let chec = !check
         props.roll[index].checks = chec
       
    }

     // 销售单位切换
    const goJiaType = (type)=>{
        if(jiaType.value!=type){
          jiaType.value = type
        }
    }

    // 交货方式
    const deliverCheck = (type)=>{
      if(props.goodsPropertys.deliverType!=type){
        props.goodsPropertys.deliverType = type
      }
    }

    // 输入框
    const numFour =(type)=>{
      // 米
      if(type=='chang'){
        if(inpshu.chang<0){
          inpshu.chang=0
        }
        let num = String(inpshu.chang).split('.')
        if(num[1]&&num[1].length>3){
          num[1] = num[1].substring(0,3)
          let nums = num.join('.')
          inpshu.chang = Number(nums)
        }
      }else if(type=='dun'){//吨
        if(inpshu.dun<0){
          inpshu.dun=0
        }
        let num = String(inpshu.dun).split('.')
        if(num[1]&&num[1].length>3){
          num[1] = num[1].substring(0,3)
          let nums = num.join('.')
          inpshu.dun = Number(nums)
        }
      }else if(type=='pian'){//片
        if(inpshu.pian<1){
          inpshu.pian=''
        }
        let num = String(inpshu.pian).split('.')
        if(num[0].length>0){
          inpshu.pian = Number(num[0])
        }
      }else if(type=='gen'){//根
        if(inpshu.gen<1){
          inpshu.gen=''
        }
        let num = String(inpshu.gen).split('.')
        if(num[0].length>0){
          inpshu.gen = Number(num[0])
        }
      }
    }

    // 加入购物车proxy.$addCart(paramsData);
    const subm = ()=>{
      let paramsData = {
        deliverType: props.goodsPropertys.deliverType,
        goodsId:props.cartInfo.goodsId,
        type:jiaType.value,
      }
      let juanFlag = false
      
      if(jiaType.value==1){ //米
        if(!inpshu.chang){
         return ElMessage('请输入长度');
        }
        let zhongc = inpshu.chang*props.goodsPropertys.meterWeight
        // ||inpshu.chang>props.goodsPropertys.goodsStockNum
        if(zhongc>props.goodsPropertys.goodsStockWeight){
          emit("ficientClose",props.cartInfo.goodsId);
          return
        }
        paramsData.number = inpshu.chang
      }else if(jiaType.value==4){//吨
        if(!inpshu.dun){
         return ElMessage('请输入吨数');
        }
        let zhongc = inpshu.dun
        if(zhongc>props.goodsPropertys.goodsStockWeight){
          emit("ficientClose",props.cartInfo.goodsId);
          return
        }
        paramsData.number = inpshu.dun
      }else if(jiaType.value==2){//片
        if(!inpshu.pian){
         return ElMessage('请输入片数');
        }
        let zhongc = inpshu.pian*props.goodsPropertys.meterWeight
        if(zhongc>props.goodsPropertys.goodsStockWeight){
          emit("ficientClose",props.cartInfo.goodsId);
          return
        }
        if(inpshu.pian>props.goodsPropertys.goodsStockNum){
          emit("ficientClose",props.cartInfo.goodsId);
          return
        }
        paramsData.number = inpshu.pian
      }else if(jiaType.value==3){//根
        if(!inpshu.gen){
         return ElMessage('请输入根数');
        }
        let zhongc = inpshu.gen*props.goodsPropertys.meterWeight
        if(zhongc>props.goodsPropertys.goodsStockWeight){
          emit("ficientClose",props.cartInfo.goodsId);
          return
        }
        if(inpshu.gen>props.goodsPropertys.goodsStockNum){
          emit("ficientClose",props.cartInfo.goodsId);
          return
        }
        paramsData.number = inpshu.gen
      }else if(jiaType.value==5){//卷
        paramsData.number = 1
        let filt = props.roll.filter(it=>{ return it.checks})
        if(filt.length==0){
          return ElMessage('请选择卷重');
        }else if(filt.length==1){
          if(filt[0].rollWight>props.goodsPropertys.goodsStockWeight){
            emit("ficientClose",props.cartInfo.goodsId);
            return
          }
          paramsData.rollWight = filt[0].rollWight
        }else if(filt.length>1){
          juanFlag = true
        }
        for(let i=0;i<filt.length;i++){
            let item = filt[i]
            if(item.rollNum==0){
               ElMessage('卷重为'+item.rollWight+'吨'+'的卷库存不足，请重新选择')
               return
            }
         }
      }
      if(!juanFlag){
        proxy.$post("v3/business/front/cart/add", paramsData).then((res) => {
            if (res.state == 200) {
                ElMessage.success(res.msg);
                emit("close");
            } else {
              if (res.msg === '可供数量不足！'||res.msg === '可供重量不足！') {
                emit("ficientClose",props.cartInfo.goodsId);
              } else {
                ElMessage.error(res.msg)
              }
                
            }
        }).then(() => {
          proxy.$getLoginCartListData();
        })
      }else{
        let cartAddList = []
        let juanc = 0
        props.roll.forEach(it=>{
            if(it.checks){
                let obj = {}
                obj.deliverType = props.cartInfo.goodsProperty.deliverType
                obj.goodsId=props.cartInfo.goodsId
                obj.number=1
                obj.productId=props.cartInfo.defaultProductId
                obj.rollWight = it.rollWight
                obj.type = 5
                cartAddList.push(obj)
                juanc+=it.rollWight
            }
        })
        juanc = juanc.toFixed(3)
        if(juanc>props.goodsPropertys.goodsStockWeight){
          emit("ficientClose",props.cartInfo.goodsId);
          return
        }
        proxy.$post("v3/business/front/cart/batchAdd", cartAddList,"application/json").then((res) => {
            if (res.state == 200) {
                ElMessage.success(res.msg);
                emit("close");
            } else {
                ElMessage.error(res.msg)
            }
        }).then(() => {
            proxy.$getLoginCartListData();
        })
      }
    }

   

    return {
      dialogVisible,
      handleClose,
      goodsInfo,
      longcheck,
      jiaType,
      goJiaType,
      inpshu,
      total_sheet,
      deliverCheck,
      total_amount,
      numFour,
      subm
    };
  },
};
</script>

<style lang="scss">
.shopcart {
  .el-dialog__header {
    background: $colorMain;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__close {
      color: #fff;
    }
  }
  .shopcart_cont {
    padding-left: 50px;
    .shopcart_box {
      display: grid;
      grid-template-columns: 15% auto;
      .p1 {
        color: rgba(187, 187, 187, 1);
        font-size: 12px;
      }
    }
    .shopcart_xiao {
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
      span {
        color: #9d9d9d;
        font-size: 14px;
        cursor: default;
      }
      .goods_company_radio {
        margin-right: 21px;
      }
    }
    .shopcart_chang {
      margin-top: 20px;
      input {
        width: 117px;
        height: 35px;
        border-radius: 3px;
        color: rgba(136, 136, 136, 1);
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
        font-family: Microsoft Yahei;
        border: 1px solid rgba(222, 219, 219, 1);
        padding: 0 5px;
      }
      span {
        margin-left: 10px;
        color: rgba(157, 157, 157, 1);
      }
      p {
        margin-top: 8px;
      }
    }
    .shopcart_jin {
      margin-top: 20px;
      .mode_type {
        img {
          width: 14px;
          height: 14px;
          cursor: pointer;
          margin-right: 5px;
        }
        .mode_type_box {
          // cursor: pointer;
        }
        span {
          color: rgba(187, 187, 187, 1);
        }
      }
      .p1 {
        margin-top: 8px;
      }
    }
    .shopcart_dan {
      margin-top: 20px;
      .shopcart_danjia{
        div{
          color: #CE4031;
          font-size: 16px;
          font-weight: bold;
        }
        span{
          color: rgba(157, 157, 157, 1);
        }
      }
    }
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
    -moz-appearance: textfield;
  }
  // .shopcart_mask {
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   background: rgba(16, 16, 16, 0.5);
  //   z-index: 99;
  // }
  .longitude_xuan{
        flex-wrap: wrap;
        .longitude_xuan_o{
            min-width: 84px;
            height: 42px;
            padding: 0 10px;
            border: 1px solid rgba(187, 187, 187, 1);
            color: rgba(16, 16, 16, 1);
            font-size: 14px;
            cursor: pointer;
            margin-right: 10px;
            margin-bottom: 10px;
        }
        .longitude_xuan_check{
            border: 1px solid rgba(23, 137, 240, 1);
        }
       }
}
.el-checkbox__label {
  line-height: 17px;
  font-size: 12px;
}
</style>