<template>
  <div class="logistics_box" v-if="ist_flag">
     <div class="logistics_box_title">
      <img src="@/assets/order/close.png" alt="" @click="add_flag(false)">
      物流运费
    </div>
     <div class="logistics_box_sel flex_row_between_center">
        <p class="logistics_box_sel_left">发货地</p>
        <el-select v-model="shipment" class="logistics_box_sel_2" placeholder="发货地" size="small" @change="changes">
          <el-option
            v-for="item in shipmentList.data"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
      </el-select>
     </div>
     <div class="logistics_box_sel flex_row_between_center">
        <p class="logistics_box_sel_left">卸货地</p>
        <!-- <el-select v-model="cityCode" class="logistics_box_sel_2" placeholder="卸货地" size="small">
          <el-option
              v-for="(item,index) in cityList.data"
              :key="index"
              :label="item.name"
              :value="item.code"
          />
        </el-select> -->
        <el-cascader  class="logistics_box_sel_2"  ref="cascaderAddr" @change="handleChange" v-model="areaList.data" :options="cityList.data" placeholder="请选择卸货地"
            :props="cascaderProps"></el-cascader>
     </div>
     <div class="logistics_box_sel flex_row_between_center">
        <p class="logistics_box_sel_left">吨位</p>
        <div>
            <el-input style="width:100px;" class="logistics_box_sel_2" size="small" placeholder="吨位"  v-model="number" @input="numFour"/><span style="color:#999;">吨</span>
        </div>
     </div>
     <div class="logistics_box_sel flex_row_between_center">
        <p class="logistics_box_sel_left">运费</p>
        <div>
            <el-input style="width:80px;" class="logistics_box_sel_2" placeholder="" v-model="calculate" size="small"/><span style="color:#999;">元/吨</span>
        </div>
     </div>
     <div class="logistics_box_btn flex_row_center_center" @click="calcul">立即查询</div>
     <div class="logistics_cont">若无报价，请拨打客服电话</div>
     <div class="logistics_cont" style="margin-top: 5px;margin-bottom: 10px;">0412-2576660</div>
  </div>
  <div v-if="!ist_flag" class="logistics_box_o" @click="add_flag(true)">展开</div>
</template>
<script>
  import { getCurrentInstance, onMounted, watchEffect, watch, ref, reactive } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import { ElMessage } from "element-plus";
  export default {
     name: "LogisticsFreight",
     props: [],
     setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        const route = useRoute()
        const L = proxy.$getCurLanguage();
        const charaList = ref([])
        const firstLoading = ref(true)
        const ist_flag = ref(true)
         const areaList = reactive({
            data:[]
        })
         // 搜索的产品名称
         const number = ref('');//吨
        const calculate = ref('');//运费
        const shipment = ref('1');//发货地
        const cityCode = ref('');//卸货地
        const cascaderProps = { label: "regionName", value: "regionCode" };
        const cascaderAddr = ref(null);
        const cityList = reactive({//目的地
          data:[]
        }) 
         const shipmentList = reactive({
          data:[
          {
            value: '1',
            label: '沈阳',
          },
          {
            value: '2',
            label: '鞍山',
          },
          ]
        })


        const changes = ()=>{
          cityCode.value = ''
          getDestination()
        }
        const add_flag = (type)=>{
          ist_flag.value = type
        }
        // 吨数据框的验证
        const numFour = ()=>{
          if(number.value<0){
              number.value=0
            }
            let num = String(number.value).split('.')
            let nums = 0
            if(num[1]&&num[1].length>2){
              num[1] = num[1].substring(0,2)
              nums = num.join('.')
              number.value = Number(nums)
            }else{
                nums = number.value
            }
        }

        // 运费获取目的地
        const getDestination = ()=>{
          let param = {
            presencePlace:shipment.value==1?'沈阳':'鞍山'
          }
          proxy.$get('v3/business/front/orderOperate/addressList',param).then(res => {
            if (res.state == 200) {
                cityList.data = res.data
            } else {
                  // inqDTO.receiverAddress = inqDTO.receiverAddress.split('/')
                  ElMessage.error(res.msg)
            }
          })
        }
        getDestination()

        // 计算
        const calcul =()=>{
          if(!cityCode.value){
            ElMessage('请选择卸货地')
            return
          }
          if(!number.value){
            ElMessage('请输入吨位')
            return
          }
          let param = {
            orginPlace:shipment.value,
            number:number.value,
            cityCode:cityCode.value
          }
          proxy.$get('v3/business/front/orderOperate/calculateExpressFee',param).then(res => {
            if (res.state == 200) {
              ElMessage.success('运费计算成功')
              calculate.value = res.data
            } else {
                  // inqDTO.receiverAddress = inqDTO.receiverAddress.split('/')
                  ElMessage.error(res.msg)
            }
          })
        }

        watchEffect(() => {
           // if (route.query.g == 1) {
           //    getCharacter()
           // }

          

        })

         const handleChange = (e)=>{
         cityCode.value = areaList.data[2]
      }
        onMounted(() => {
           
        });

        return { 
          L, 
          charaList,
          firstLoading,
          shipment,
          shipmentList,
          changes,
          cityList,
          calculate,
          cityCode,
          numFour,
          calcul,
          number,
          ist_flag,
          add_flag,
          cascaderProps,
          handleChange,
          cascaderAddr,
          areaList
         };
     },
  };
</script>
<style lang="scss">
  .clearfix {
     display: block;
     zoom: 1;
  }

  ul,
  ol,
  li {
     list-style: none;
  }

  a:visited {
     text-decoration: none;
  }
  .logistics_box{
    position: fixed;
    z-index: 2000;
    right: 73px;
    bottom: 100px;
    width: 206px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 1);
    text-align: center;
    border: 1px solid rgba(187, 187, 187, 1);
    font-size: 15px;
    .logistics_box_title{
      color: rgba(16, 16, 16, 1);
      margin: 10px 0;
      margin-bottom: 10px;
      font-weight: bold;
      position: relative;
      img{
        width: 20px;
        height: 20px;
        position: absolute;
        left: -10px;
        top: -20px;
        cursor: pointer;
      }
    }
    .logistics_box_sel{
      margin:15px 0;
      padding: 0 11px;
      .logistics_box_sel_left{
        color: #101010;
      }
      .logistics_box_sel_2{
        width: 116px;
        border-radius: 4px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.26);
      }
    }
    .logistics_box_btn{
      width: 96px;
      height: 30px;
      border-radius: 4px;
      background-color: rgba(23, 123, 255, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      text-align: center;
      font-family: Microsoft Yahei;
      margin: 0 auto;
      cursor: pointer;
    }
    .logistics_cont{
      color: rgba(123, 114, 114, 1);
      font-size: 14px;
      text-align: center;
      font-family: SourceHanSansSC-regular;
      margin-top: 10px;

    }
  }
  .logistics_box_o{
    position: fixed;
    z-index: 999;
    background: #fff;
    right: 10px;
    bottom: 470px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.26);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
      color: #285fde;
    }
  }
</style>