<template>
     <div class="quick_order_main con1">
          <div class="quick_order">

               <div class="operate_style flex_row_start_center">
                    <span>操作方式：</span>
                    <div class="op_item" :class="{ chosen: opType == 1 }" @click="opType = 1">手动添加</div>
                    <div class="op_item" :class="{ chosen: opType == 2 }" @click="opType = 2">模板导入</div>
               </div>




               <div class="table_list" v-show="opType == 1">
                    <div class="header flex_row_start_center">
                         <div class="index">序号</div>
                         <div class="product_code">订货编号</div>
                         <!-- <div class="material_code" v-if="memberInfo.memberType == 3">物料编码</div> -->
                         <div class="material_code">物料编码</div>
                         <!-- <div class="number">数量</div> -->
                    </div>

                    <div class="body">
                         <div class="body_item_wrap">
                              <div class="body_item flex_row_start_center" v-for="(item, index) in quickDTO"
                                   :key="item.index">
                                   <div class="index1">{{ index + 1 }}</div>
                                   <div class="product_code1">
                                        <input type="text" v-model="item.orderCode" :key="index"
                                             @input="handleInput(item)">
                                   </div>
                                   <!-- <div class="material_code1" v-if="memberInfo.memberType == 3">
                                        <input type="text" v-model="item.matCode" @input="handleInput(item)">
                                   </div> -->
                                   <div class="material_code1">
                                        <input type="text" v-model="item.matCode" @input="handleInput(item)">
                                   </div>
                                   <!-- <div class="number1">
                                        <input type="number" v-model="item.number" @input="editNum('inputNum', item)"
                                             @blur="editNum('blurNum', item)">
                                   </div> -->

                                   <div class="match_res">{{ item.quireState }}</div>


                                   <div class="del_res" @click="kickCode(index)" v-if="quickDTO.length > 1">删除</div>
                              </div>

                         </div>

                         <div class="add_line flex_row_end_center" @click="addCode">
                              <span>+ 添加</span>
                         </div>


                         <div class="query_go flex_row_center_center" @click="searchSpec">
                              <div>查询</div>
                         </div>
                    </div>
               </div>



               <div class="up_file" v-show="opType == 2">
                    <div class="upload flex_column_center_center" id="dropZone" v-loading="uploading">
                         <label for="upload">
                              <div class="flex_column_center_center">
                                   <div class="el-icon-plus"></div>
                                   <div class="errMsg" v-if="errMsg.errFlag">
                                        <span class="errTxt1">导入失败！</span>
                                        <span class="errTxt2">您可以</span>
                                        <a class="errTxt3" @click="downErrFile">下载错误表格</a>
                                        <span class="errTxt2">或重新上传。</span>
                                   </div>
                                   <div class="txt4" v-else>点击上传或者拖拽文件到该区域即可</div>

                              </div>

                         </label>
                         <input type="file" name="" id="upload"
                              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              @change="getFile" ref="inputFile">
                    </div>

                    <div class="tip_now">
                         <div class="flex_row_start_center">
                              <span> · 下载标准模板，按照模板要求填写数据。 </span>
                              <div class="option2" @click="downTemplateFile">下载标准模版>></div>
                         </div>
                         <div> · 请选择.xls文件，每次只能导入一个文件，建议每次导入不超过&nbsp;100&nbsp;条产品数据</div>
                    </div>
               </div>





               <!-- <div v-if="noMatchList.length" class="noMatchList">
                    <div class="noMatchList_tip">
                         <span>已展示全部查询结果，以下型号暂无匹配产品。</span>
                         <span>复制以下型号</span>
                         <span class="iconfont iconzhuantimoban" @click="copy('.iconzhuantimoban')"></span>
                    </div>
                    <div class="noMatchList_list">
                         <div :class="{hideMore}"><template v-for="item in noMatchList" :key="item">{{item.spec}};
                              </template></div>
                         <span v-if="hasMore && showMoreBtn" @click="changeHide">{{hideMore?'全部>>':'隐藏>>'}}</span>
                    </div>
               </div> -->
               <div class="order_table" v-if="empty">
                    <el-table ref="multipleTable" :data="productList" style="width:1200px;"
                         @selection-change="handleSelectionChange">
                         <el-table-column type="selection" width="40" />
                         <!-- <el-table-column type="index" width="55" label="序号" align="center" /> -->

                         <el-table-column label="图片" width="100" align="center">
                              <template #default="scope">
                                   <div class="pro_img flex_row_center_center">
                                        <img :src="scope.row.goodsImage" alt="">
                                   </div>
                              </template>
                         </el-table-column>
                         <!-- <el-table-column property="goodsSpec" label="规格型号" width="320" /> -->
                         <el-table-column label="产品名称" width="150">
                              <template #default="scope">
                                   <div class="bd14">
                                        <p>{{ scope.row.goodsName }}</p>
                                        <p class="word95">{{ scope.row.specValues }}</p>
                                        <br />
                                        <span class="word95 word95_1" @click="emitContrast(scope.row)">加入对比</span>
                                        <!-- <p class="word95" v-if="scope.row.orderCode">订货编码：{{ scope.row.orderCode }}</p> -->
                                        <!-- <span  >{{ attr.attributeName }}:{{ attr.attributeValue }}</span> -->
                                   </div>

                              </template>
                         </el-table-column>
                         <el-table-column property="goodsSpec" align="center" label="规格" width="100">
                              <template #default="scope">
                                   <div class="bd15 flex-col">
                                        <span class="info54">{{ scope.row.goodsProperty.specModel?scope.row.goodsProperty.specModel:'--' }}</span>
                                   </div>
                              </template>
                         </el-table-column>
                         <el-table-column property="goodsProperty" align="center" label="材质" width="100">
                              <template #default="scope">
                                   <div class="bd15 flex-col">
                                        <span class="info54">{{ scope.row.goodsProperty.material?scope.row.goodsProperty.material:'--' }}</span>
                                   </div>
                              </template>
                         </el-table-column>
                         <el-table-column property="goodsProperty" align="center" label="产地" width="100">
                              <template #default="scope">
                                   <div class="bd15 flex-col">
                                        <span class="info54">{{ scope.row.goodsProperty.originPlace?scope.row.goodsProperty.originPlace:'--' }}</span>
                                   </div>
                              </template>
                         </el-table-column>
                         <el-table-column label="单价（元/吨）" align="center" width="150">
                              <template #default="scope">
                                   <div class="bd16 flex-col" v-if="scope.row.goodsPrice">￥{{
                                        scope.row.goodsProperty.goodsPrice.toFixed(2)
                                   }}/吨</div>
                                   <div class="need_inquire type1" v-else></div>
                                   <div class="bd16_o" style="margin-top: 10px;">可供重量：{{scope.row.goodsProperty.goodsStockWeight}}吨</div>
                                   <div class="bd16_o">可供数量：{{scope.row.goodsProperty.goodsStockNum}}</div>
                              </template>
                         </el-table-column>
                         <el-table-column label="货物存在地" align="center" property="goodsProperty" width="150">
                              <template #default="scope">
                                   <div class="bd15 flex-col">
                                        <span class="info54">{{ scope.row.goodsProperty.presencePlace==1?'沈阳':scope.row.goodsProperty.presencePlace==2?'鞍山':'--'}}</span>
                                        <!-- :scope.row.goodsProperty.presencePlace==3?'鞍山' -->
                                   </div>
                              </template>
                         </el-table-column>
                         <el-table-column label="操作" align="center" property="" width="150">
                              <template #default="scope">
                                   <div class="bd15 flex-col">
                                        <span class="info54" style="cursor: pointer;" @click="addcart(scope.row)">加入购物车</span>
                                   </div>
                              </template>
                         </el-table-column>
                         <!-- <el-table-column label="购买数量" width="200">
                              <template #default="scope">
                                   <div class="section25">
                                        <div class="flex_row_start_center">
                                             <div class="goods_edit_nem flex_row_center_center">
                                                  <span @click="editNum('minus', scope.row)">-</span>
                                                  <input type="number" @input="editNum('edit', scope.row)"
                                                       @blur="editNum('blur', scope.row)" v-model="scope.row.goodsNum"
                                                       :key="scope.row.goodsId" @focus="editNum('focus', scope.row)" />
                                                  <span @click="editNum('plus', scope.row)">+</span>
                                             </div>
                                             <span class="txt50">{{ scope.row.saleUnit }}</span>
                                        </div>
                                        <div class="txt50">{{ scope.row.saleUnitDes }}</div>
                                        <div class="txt50">最低起购{{ scope.row.minBuyNum }}{{ scope.row.saleUnit }}</div>
                                   </div>
                              </template>
                         </el-table-column> -->
                    </el-table>
               </div>

               <div class="empty_table" v-if="!productList.length && empty">
                    <SldCommonEmpty></SldCommonEmpty>
               </div>

               <div class="option_bottom flex_row_between_center" v-if="productList.length && proSum">


                    <div class="op_left">
                         <span @click="delChosen">删除选中的产品</span>
                    </div>


                    <div class="op_right flex_row_start_center">
                         <div class="txt flex_row_start_center">
                              <span class="txt1">已选择&nbsp;</span>
                              <span class="txt2">{{ proSum }}</span>
                              <span class="txt1">&nbsp;件产品</span>
                         </div>
                         <!-- <div class="txt flex_row_start_center" v-if="noInquire">
                              <span class="txt1">合计：</span>
                              <span class="txt2">￥{{ proPriceSum.toFixed(2) }}</span>
                         </div> -->
                         <!-- <div class="but" @click="toOrder">
                              加入购物车
                         </div> -->
                    </div>
               </div>
          </div>

     </div>
     <LogisticsFreight></LogisticsFreight>
     <Insufficient v-if="ficientVisible" @Close="ficientguan" :goodsId="cartInfos"></Insufficient>
     <shoppingCart v-if="dialogVisible" @ficientClose="ficientClose" :deliverType="deliverType"  @close='shoppclose' :DialogType="DialogType" :roll="cartInfo.data.goodsProperty.roll" :cartInfo="cartInfo.data" :goodsPropertys="cartInfo.data.goodsProperty"></shoppingCart>
</template>
<script>
import SldCommonEmpty from '@/components/SldCommonEmpty.vue'
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import addCompare from '@/components/comparePanel/compare.js'
import LogisticsFreight from "@/components/LogisticsFreight";
import Insufficient from '@/components/Insufficient'
import Clipboard from 'clipboard';
import shoppingCart from '@/components/shoppingCart'
export default {
     components: {
          SldCommonEmpty,
          shoppingCart,
          Insufficient,
          LogisticsFreight
     },
     setup() {
          const visibleFlag = ref(false)
          const searchArea = ref('')
          const { proxy } = getCurrentInstance()
          const productList = ref([])
          const noMatchList = ref([]);
          const visibleDo = () => {
               visibleFlag.value = true

          }
          const dialogVisible = ref(false)//加入购物车弹框
          const deliverType = ref('')//是否净板交货
          const ficientVisible = ref(false)//库存不足弹框
          const cartInfo = reactive({
                    data:{}
               })//加入购物车弹框里的数据
          const cartInfos = ref('')//加入购物车弹框里的数据
          const uploading = ref(false)
          const proSum = ref(0)
          const proPriceSum = ref(0)
          const preEditNum = ref(0)
          const checkList = ref([])
          const router = useRouter()
          const hasMore = ref(true);
          const hideMore = ref(false);
          const showMoreBtn = ref(false);
          const opType = ref(1)
          const store = useStore()
          const fileDrag = () => {
               var dz = document.getElementById('dropZone');
               dz.ondragover = function (e) {
                    //阻止浏览器默认打开文件的操作  
                    e.preventDefault();
                    e.stopPropagation();
                    //拖入文件后边框颜色变红  
                    e.dataTransfer.dropEffect = 'copy';
               }
               dz.ondragleave = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    //恢复边框颜色  
                    this.style.borderColor = 'gray';
               }
               dz.ondrop = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    //恢复边框颜色  
                    this.style.borderColor = '#d9d9d9';
                    //阻止浏览器默认打开文件的操作  
                    e.preventDefault();
                    let filesTarget = e.dataTransfer.files;
                    uploadFile(filesTarget)
               }
          }
          const doSearch = ref(false)
          const empty = ref(false)
          const memberInfo = ref(store.state.memberInfo)
          const errMsg = reactive({
               errFlag: false,
               errFile: ''
          })

          const noInquire = ref(true)
          const productListDTO = {
               orderCode: '',
               matCode: '',
               number: 1,
               quireState: ''
          }

          let keyArray = Array(5).fill({}).map((val, index) => {
               return {
                    ...JSON.parse(JSON.stringify(productListDTO)),
                    index
               }
          })

          const quickDTO = ref(keyArray)


          const getFile = (e) => {
               uploadFile(e.target.files)
          }


          const handleInput = (item) => {
               item.quireState = ''
               doSearch.value = false
          }

          const uploadFile = (info) => {
               let file = info[0]
               let typelist = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
               let index = typelist.findIndex(i => file.type == i)
               if (index < 0) {
                    ElMessage('上传的文件格式不正确')
                    return
               }

               if (file) {
                    uploading.value = true
                    proxy.$post('v3/goods/front/goodsSpec/import', { file }, 'form').then(res => {
                         if (res.state == 200) {
                              productList.value = []
                              ElMessage.success(res.msg)
                              uploading.value = false
                              empty.value = true
                              productList.value = res.data.goodsList
                              proxy.$refs.inputFile.value = ""
                         } else {
                              ElMessage(res.msg)
                              uploading.value = false
                         }

                         if (res.state == 200) {
                              uploading.value = false
                              errMsg.errFlag = false
                         } else if (res.state == 267) {
                              errMsg.errFlag = true
                              errMsg.errFile = res.data
                         }
                    })
               }
          }

          const searchSpec = () => {
               if (doSearch.value) return
               doSearch.value = true
               let goodsSelectDTOList = []

               for (let i in quickDTO.value) {
                    if (!checkLine(quickDTO.value[i])) {
                         return
                    } else {
                         let { orderCode, matCode, number } = quickDTO.value[i];

                         (orderCode || matCode) && goodsSelectDTOList.push({
                              num: number,
                              orderCode,
                              productCode: matCode
                         })
                    }
               }

               if (!goodsSelectDTOList.length) {
                    ElMessage('请输入数据')
                    doSearch.value = false
                    return
               }



               // if (noMatchList.value.length) {
               //      noMatchList.value = [];
               //      hasMore.value = true;
               //      hideMore.value = false;
               //      showMoreBtn.value = false;
               // }

               proxy.$post('v3/goods/front/goods/list', goodsSelectDTOList, 'json').then(res => {
                    doSearch.value = false
                    if (res.state == 200) {
                         empty.value = true
                         productList.value = res.data.goodsList.map(item => {
                              if (item.produceState != 1) item.productStock = 99999
                              return item
                         })

                         // if (res.data.specInfo) {
                         //      let arr = res.data.specInfo.split(';');
                         //      let arr2 = [];
                         //      arr.forEach((item, index) => {
                         //           arr2.push({
                         //                spec: item.split(',')[0],
                         //                num: item.split(',')[1],
                         //                search: item.split(',')[0] + ',' + item.split(',')[1]
                         //           })
                         //      })
                         //      noMatchList.value = arr2;

                         //      if (noMatchList.value.length) {
                         //           setTimeout(() => {
                         //                let dom = document.getElementsByClassName('noMatchList_list')[0];
                         //                hideMore.value = true;
                         //                if (dom.clientHeight > 36) {
                         //                     hasMore.value = true;
                         //                     showMoreBtn.value = true;
                         //                } else {
                         //                     hasMore.value = false;
                         //                }
                         //           })
                         //      }
                         // }

                         for (let i in quickDTO.value) {
                              let { orderCode, matCode } = quickDTO.value[i];
                              let queryVal = productList.value.find(i => (i.orderCode == orderCode || i.productCode == matCode))
                              if (!queryVal && (orderCode || matCode)) {
                                   quickDTO.value[i].quireState = '暂无匹配产品'
                              } else if (queryVal && (orderCode || matCode)) {
                                   quickDTO.value[i].quireState = '匹配成功'

                              }
                         }

                    }
               })
          }

          // 加入对比
          const emitContrast = (item) => {
               let paramsData = {
                    productImage: item.goodsImage,
                    productId: item.defaultProductId,
                    productName: item.goodsName,
                    specValues: item.specValues,
                    type: 'product'
               };
               //加入对比
               addCompare(paramsData)
               // emit('contrast', paramsData)
          }

          const downTemplateFile = () => {
               proxy.$get('v3/goods/front/goodsSpec/download', {}, 'blob').then(res => {
                    let tmp = new Blob([res], { type: 'application/vnd.ms-excel' })
                    const aLink = document.createElement('a');
                    document.body.appendChild(aLink);
                    aLink.style.display = 'none';
                    aLink.href = window.URL.createObjectURL(tmp);
                    aLink.setAttribute('download', '选型标准模板');
                    aLink.click();
                    document.body.removeChild(aLink);
               })
          }

          const editNum = (type, scopeItem) => {
               switch (type) {
                    case 'minus': {
                         if (scopeItem.goodsNum <= scopeItem.minBuyNum) {
                              return
                         } else {
                              scopeItem.goodsNum--
                              if (proSum.value) {
                                   proPriceSum.value -= scopeItem.goodsPrice
                              }
                         }
                         break
                    }
                    case 'edit': {
                         if ((!/^[1-9]\d*$/.test(scopeItem.goodsNum) && scopeItem.goodsNum)) {
                              scopeItem.goodsNum = scopeItem.minBuyNum
                         } else if (scopeItem.goodsNum >= 99999) {
                              ElMessage('超出购买限制')

                              scopeItem.goodsNum = 99999
                         }

                         if (scopeItem.goodsNum && scopeItem.goodsNum >= scopeItem.minBuyNum) {
                              if (proSum.value) {
                                   proPriceSum.value = ((scopeItem.goodsNum) * scopeItem.goodsPrice)
                              }
                         } else {
                              if (proSum.value) {
                                   proPriceSum.value = ((scopeItem.minBuyNum) * scopeItem.goodsPrice)
                              }
                         }


                         break
                    }
                    case 'plus': {
                         scopeItem.goodsNum++


                         if (scopeItem.goodsNum > 99999) {
                              scopeItem.goodsNum = 99999
                              ElMessage('超出购买限制')
                         } else {
                              if (proSum.value) {
                                   proPriceSum.value += scopeItem.goodsPrice
                              }
                         }


                         break;
                    }
                    case 'focus': {
                         preEditNum.value = scopeItem.goodsNum
                         break;
                    }

                    case 'blur': {
                         if (scopeItem.goodsNum <= scopeItem.minBuyNum) {
                              scopeItem.goodsNum = scopeItem.minBuyNum
                         }

                         break
                    }


                    case 'inputNum': {
                         if ((!/^[1-9]\d*$/.test(scopeItem.number) && scopeItem.number)) {
                              scopeItem.goodsNum = 1
                         } else if (scopeItem.number >= 99999) {
                              scopeItem.number = 99999
                         }

                         break
                    }

                    case 'blurNum': {
                         if (!scopeItem.number) {
                              scopeItem.number = scopeItem.minBuyNum
                         }

                         break
                    }
               }

               if (proSum.value) {
                    let idx = checkList.value.findIndex(i => i.productId == scopeItem.productId)
                    if (idx > -1) {
                         checkList.value[idx].num = scopeItem.goodsNum
                    }
               }
          }

          const handleSelectionChange = (selec) => {
               proSum.value = selec.length
               noInquire.value = selec.every(i => i.goodsPrice > 0)
               checkList.value = selec.map(item => {
                    return {
                         productId: item.productId,
                         num: item.goodsNum
                    }
               })
               proPriceSum.value = selec.reduce((total, obj) => total + (obj.goodsPrice * obj.goodsNum), 0)
          }

          const toOrder = () => {


               let tmp = checkList.value.map(i => `${i.productId}-${i.num}`)
               let query = {
                    productSets: encodeURIComponent(tmp.join(','))
               }
               router.push({
                    path: "/buy/confirm",
                    query
               })
          }

          const downErrFile = () => {
               const aLink = document.createElement('a');
               document.body.appendChild(aLink);
               aLink.style.display = 'none';
               aLink.href = errMsg.errFile;
               aLink.setAttribute('download', '错误表格');
               aLink.click();
               document.body.removeChild(aLink);
          }

          //复制
          const copy = (classname) => {
               let search = '';
               noMatchList.value.forEach((item, index) => {
                    search += (index == 0 ? '' : ';') + item.search;
               })
               var clipboard = new Clipboard(classname, {
                    text: () => search
               });
               clipboard.on('success', e => {
                    ElMessage.success('复制成功');
                    //  释放内存
                    clipboard.destroy()
               })
               clipboard.on('error', e => {
                    ElMessage.error('您的浏览器不支持快捷复制');
                    clipboard.destroy()
               })
          };

          const changeHide = () => {
               hideMore.value = !hideMore.value;
          };

          const addCode = () => {

               let newList = [
                    JSON.parse(JSON.stringify(productListDTO)),
                    JSON.parse(JSON.stringify(productListDTO)),
                    JSON.parse(JSON.stringify(productListDTO)),
                    JSON.parse(JSON.stringify(productListDTO)),
                    JSON.parse(JSON.stringify(productListDTO))
               ]



               if (quickDTO.value.length + 5 >= 100) {
                    newList = newList.slice(0, 100 - quickDTO.value.length)
               }





               keyArray = keyArray.concat(newList).map((val, index) => {
                    return { ...val, index }
               })


               quickDTO.value = keyArray
          }

          const kickCode = (index) => {
               quickDTO.value.splice(index, 1)
          }

          const checkLine = (item) => {
               if (memberInfo.memberType == 3) {
                    if (!item.matCode) {
                         item.quireState = '请输入物料编码'
                         return false
                    }
               }

               if (item.number <= 0 || item.number > 99999) {
                    item.quireState = '数量在1~99999区间'
                    return false
               }

               return true
          }


          const delChosen = () => {
               checkList.value.forEach(val => {
                    let idx = productList.value.findIndex(i => i.productId == val.productId)
                    productList.value.splice(idx, 1)
               })
          }
          // 关闭购物车并打开库存不足框
          const ficientClose = (infos)=>{
               dialogVisible.value = false
               cartInfos.value = infos
               ficientVisible.value = true
          }

           // 关闭库存不足框
          const ficientguan = ()=>{
          cartInfos.value = ''
          dialogVisible.value = false
          ficientVisible.value = false
          }
          // 关闭购物车弹框
          const shoppclose = ()=>{
          
               dialogVisible.value = false
               cartInfo.data = {}
          }

          const DialogType = ref(1);
          // 加入购物车弹框
          const addcart = (item)=>{
               if(item.goodsProperty.roll.length>0){
                    item.goodsProperty.roll.forEach(item=>{
                         item.checks = false
                    })
               }
               if(item.goodsProperty.meterWeight){
                    DialogType.value = 1
               }else if(item.goodsProperty.isSupportKg==1){
                    DialogType.value = 4
               }else if(item.goodsProperty.roll.length>0){
                    DialogType.value = 5
               }else if(item.goodsProperty.sliceWeight){
                    DialogType.value = 2
               }else if(item.goodsProperty.rootWeight){
                    DialogType.value = 3
               }
               cartInfo.data = JSON.parse(JSON.stringify(item))
               deliverType.value = JSON.parse(JSON.stringify(item)).goodsProperty.deliverType
               dialogVisible.value = true
          }

          onMounted(() => {
               setTimeout(() => {
                    fileDrag()
               }, 500)
          })

          return {
               delChosen,
               visibleFlag,
               visibleDo,
               searchArea,
               searchSpec,
               productList,
               editNum,
               proSum,
               handleSelectionChange,
               proPriceSum,
               getFile,
               downTemplateFile,
               uploading,
               toOrder,
               doSearch,
               empty,
               errMsg,
               downErrFile,
               noMatchList,
               copy,
               hasMore,
               hideMore,
               changeHide,
               showMoreBtn,
               noInquire,
               opType,
               quickDTO,
               addCode,
               kickCode,
               memberInfo,
               handleInput,
               emitContrast,
               dialogVisible,
               ficientVisible,
               cartInfo,
               cartInfos,
               ficientClose,
               addcart,
               DialogType,
               ficientguan,
               shoppclose,
               deliverType
          }
     }
}
</script>
<style lang="scss" scoped>
.quick_order {
     width: 1200px;
     margin: 0 auto;
     padding-top: 20px;
     padding-bottom: 50px;


     /*** 消除input元素 type="number" 时默认的 加减按钮*/
     input[type="number"]::-webkit-inner-spin-button,
     input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
     }

     /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
     input[type="number"] {
          -moz-appearance: textfield;
     }

     .operate_style {
          span {
               font-size: 14px;
               font-family: SourceHanSansCN-Regular;
               line-height: 18px;
               color: #333333;
          }

          .op_item {
               margin-left: 10px;
               width: 100px;
               height: 36px;
               opacity: 1;
               border-radius: 18px;
               text-align: center;
               line-height: 36px;
               font-size: 14px;
               font-family: SourceHanSansCN-Regular;
               color: #333333;
               cursor: pointer;

               &.chosen {
                    background: #285FDE;
                    color: #FFFFFF;

               }
          }
     }

     .table_list {
          border: 1px solid #EEEEEE;
          margin-top: 20px;

          .header {
               height: 40px;
               background: #F9F9F9;
               line-height: 40px;
               font-size: 14px;
               font-family: SourceHanSansCN-Medium;
               line-height: 24px;
               color: #333333;

               .index {
                    margin-left: 27px;
               }

               .product_code {
                    margin-left: 115px;
               }

               .material_code {
                    margin-left: 238px;
               }

               .number {
                    margin-left: 231px;
               }
          }

          .body {

               .body_item_wrap {
                    max-height: 700px;
                    overflow: auto
               }



               .body_item {
                    height: 72px;
                    font-size: 14px;
                    color: #333333;
                    border-bottom: 1px solid #EEEEEE;



                    .index1 {
                         width: 50px;
                         text-align: center;
                         margin-left: 18px;
                    }

                    .product_code1 {
                         margin-left: 20px;

                         input {
                              width: 220px;
                              height: 32px;
                              background: #FFFFFF;
                              border: 1px solid #ABABAB;
                              padding: 0 10px;
                         }
                    }

                    .material_code1 {
                         margin-left: 75px;

                         input {
                              width: 220px;
                              height: 32px;
                              background: #FFFFFF;
                              border: 1px solid #ABABAB;
                              padding: 0 10px;
                         }
                    }

                    .number1 {
                         margin-left: 75px;

                         input {
                              width: 170px;
                              height: 32px;
                              background: #FFFFFF;
                              border: 1px solid #ABABAB;
                              padding: 0 10px;

                         }
                    }

                    .match_res {
                         width: 120px;
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         line-height: 21px;
                         color: $colorMain2;
                         margin-left: 50px;
                    }

                    .del_res {
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         line-height: 21px;
                         color: #285FDE;
                         margin-left: 60px;
                         cursor: pointer;
                    }
               }


               .add_line {
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    line-height: 21px;
                    color: #285FDE;
                    margin-top: 14px;
                    padding-right: 90px;

                    span {
                         cursor: pointer;

                         &:hover {
                              text-decoration: underline;
                         }
                    }
               }


               .query_go {
                    margin-top: 26px;
                    margin-bottom: 24px;

                    div {
                         font-weight: 400;
                         background: $colorMain;
                         cursor: pointer;
                         width: 220px;
                         height: 40px;
                         line-height: 40px;
                         text-align: center;
                         font-size: 16px;
                         font-family: SourceHanSansCN-Regular;
                         color: #FFFFFF;

                    }
               }
          }

     }

     .up_file {
          width: 100%;
          margin-top: 20px;

          .upload {

               border: 1px solid #EEEEEE;
               background: #F9F9F9;
               width: 100%;
               height: 300px;


               .el-icon-plus {
                    font-size: 26px;
                    font-weight: bolder;
                    color: #BFBFBF;
               }

               .txt4 {
                    margin-top: 20px;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 24px;
                    color: #999999;
               }
          }

          #upload {
               display: none;
          }

          .errMsg {
               font-size: 20px;
               font-family: Microsoft YaHei;
               color: #999999;
               font-weight: 400;
               margin-top: 20px;


               /* .errTxt1 {
                         color: #EB1010;
                    } */

               /* .errTxt2 {
                         color: #333333;
                    } */

               .errTxt3 {
                    color: $colorMain;
                    cursor: pointer;

                    &:hover {
                         text-decoration: underline;
                    }
               }
          }


          .tip_now {
               margin-top: 20px;
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               line-height: 24px;
               color: #999999;

               .option2 {
                    color: $colorMain;
                    border-bottom: 1px solid $colorMain;
                    font-size: 14px;
                    margin-right: 20px;
                    cursor: pointer;
               }
          }


     }


     .search_area {
          height: 120px;
          background: #FFFFFF;
          border: 1px solid #EEEEEE;
          padding: 20px;

          textarea {
               width: 100%;
               resize: none;
               font-size: 14px;
               font-family: SourceHanSansCN-Regular, SourceHanSansCN;
               font-weight: 400;
               color: #333333;
               border: none;
               outline: none;
          }

          textarea::placeholder {
               color: #c6c9ce;
          }
     }

     .tip {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #999999;
          padding-left: 30px;
          margin-top: 20px;
     }

     .options_con {
          margin-top: 30px;

          .option1 {
               color: #fff;
               font-size: 14px;
               font-family: PingFangSC-Regular, PingFang SC;
               font-weight: 400;
               padding: 8px 15px;
               background: $colorMain;
               margin-right: 20px;
               cursor: pointer;
          }

          .option2 {
               color: $colorMain;
               border-bottom: 1px solid $colorMain;
               font-size: 14px;
               margin-right: 20px;
               cursor: pointer;
          }
     }

     .order_table {
          margin-top: 20px;

          .pro_img {
               width: 89px;
               height: 89px;
               background: #FFFFFF;
               border: 1px solid #E2E2E2;
               margin: 0 auto;

               img {
                    width: 89px;
                    height: 89px;
               }
          }

          .bd14 {
               .word95 {
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    line-height: 20px;
               }
               .word95_1{
                    cursor: pointer;
                    overflow-wrap: break-word;
                    color: #285fde;
                    font-size: 12px;
                    border-bottom: 1px solid #285fde;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
               }
          }

          .bd15 {
               .info54 {
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #686868;
                    line-height: 20px;
               }
          }

          .bd16 {
               font-size: 12px;
               font-family: SourceHanSansCN-Bold, SourceHanSansCN;
               font-weight: bold;
               color: #F30213;
               line-height: 18px;
          }
          .bd16_o{
               font-size: 12px;
               font-family: SourceHanSansCN-Bold, SourceHanSansCN;
               font-weight: bold;
               color: #888888;
               line-height: 18px;
          }

          .section25 {

               justify-content: space-between;

               .txt50 {
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    margin-top: 7px;
               }

               .goods_edit_nem {

                    background: #ffffff;
                    border: 1px solid #dddddd;
                    margin-right: 10px;

                    span {
                         width: 26px;
                         height: 30px;
                         background: #fff;
                         text-align: center;
                         display: block;
                         line-height: 30px;
                         cursor: pointer;
                    }

                    input {
                         width: 44px;
                         height: 30px;
                         border: none;
                         border-left: 1px solid #dddddd;
                         border-right: 1px solid #dddddd;
                         text-align: center;
                         line-height: 30px;
                    }

                    /*** 消除input元素 type="number" 时默认的 加减按钮*/
                    input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button {
                         -webkit-appearance: none;
                         margin: 0;
                    }

                    /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
                    input[type="number"] {
                         -moz-appearance: textfield;
                    }
               }
          }
     }

     .empty_table {
          background: #fff;
          margin-top: 30px;
     }

     .option_bottom {
          margin-top: 40px;
          height: 80px;
          background: #F3F3F3;
          border: 1px solid #F0F0F0;

          .op_left {
               padding-left: 14px;

               .txtMain {
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: $colorMain;
                    margin-left: 20px;
               }
          }

          .op_left {
               span {
                    color: $colorMain;
                    cursor: pointer;

                    font-size: 14px;

                    &:hover {
                         text-decoration: underline;
                    }
               }
          }

          .op_right {
               .txt {
                    margin-right: 20px;

                    .txt1 {
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: #333333;
                    }

                    .txt2 {
                         font-size: 18px;
                         font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                         font-weight: bold;
                         color: $colorMain2;
                    }
               }

               .but {
                    width: 190px;
                    height: 80px;
                    background: $colorMain;
                    font-size: 20px;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 80px;
                    cursor: pointer;
               }
          }
     }
}

.section1 {
     z-index: 4;
     /* height: 395px; */
     border-radius: 4px;
     background-color: rgba(255, 255, 255, 1);
     width: 100%;
     justify-content: flex-start;

     .mod1 {
          width: 100%;
          /* height: 395px; */

          .outer2_new {
               z-index: 9;
               /* height: 208px; */
               background-color: rgba(255, 255, 255, 1);
               align-self: center;
               width: 100%;
               justify-content: center;
               align-items: center;
               padding: 20px;
               /* .mod2 {
                         z-index: auto;
                         width: 182px;
                         height: 102px;

                         .img1 {
                              z-index: 10;
                              width: 50px;
                              height: 50px;
                              align-self: center;
                         }

                         .layer11 {
                              z-index: 16;
                              width: 182px;
                              height: 22px;
                              overflow-wrap: break-word;
                              text-align: left;
                              white-space: nowrap;
                              margin-top: 30px;
                              font-size: 0;

                              .word2 {
                                   font-size: 14px;
                                   font-family: PingFangSC-Regular;
                                   color: rgba(153, 153, 153, 1);
                                   line-height: 22px;
                                   overflow: hidden;
                                   text-overflow: ellipsis;
                              }

                              .word3 {
                                   font-size: 14px;
                                   font-family: PingFangSC-Regular;
                                   color: rgba(40, 95, 222, 1);
                                   line-height: 22px;
                                   overflow: hidden;
                                   text-overflow: ellipsis;
                                   cursor: pointer;
                              }
                         }
                    } */

               .wrap2 {
                    background-color: rgba(40, 95, 222, 0.03);
                    color: $colorMain;
                    opacity: 0.85;
                    padding: 10px;

                    .word1 {
                         font-size: 18px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 18px;
                         text-align: left;
                         margin-bottom: 5px;
                    }

                    .main15 {
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         text-align: left;
                         margin-top: 10px;
                    }
               }

               .wrap3 {
                    margin-top: 40px;

                    .wrap_step {
                         width: 100%;

                         .steps_row {
                              margin-right: 15px;

                              .circle1 {
                                   width: 40px;
                                   height: 40px;
                                   background: $colorMain;
                                   border-radius: 50%;
                                   font-size: 24px;
                                   font-family: HelveticaNeue;
                                   color: #FFFFFF;
                                   text-align: center;
                                   line-height: 40px;
                                   margin-right: 12px;

                                   &.circle2 {
                                        color: rgba(0, 0, 0, 0.25) !important;
                                        background: #fff !important;
                                        border: 1px solid rgba(0, 0, 0, 0.25);
                                   }

                                   &.circle3 {
                                        color: $colorMain !important;
                                        background: #fff !important;
                                        border: 1px solid $colorMain;
                                   }
                              }

                              .step_txt1 {
                                   color: $colorMain;
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Medium;
                                   white-space: nowrap;

                                   &.txt2 {
                                        color: rgba(0, 0, 0, 0.25);
                                   }
                              }
                         }

                         .line1 {
                              width: 150px;
                              height: 1px;
                              background: #999;
                              margin-right: 15px;

                         }
                    }

                    .step_swiper {
                         margin-top: 25px;
                         width: 100%;

                         .step1 {
                              width: 100%;
                              height: 150px;

                              .option2 {
                                   color: $colorMain;
                                   border-bottom: 1px solid $colorMain;
                                   font-size: 14px;
                                   cursor: pointer;
                              }

                         }


                         .step3 {
                              width: 100%;
                              height: 170px;

                              .step3_txt1 {
                                   font-size: 16px;
                                   font-weight: 600;
                                   color: #333333;
                              }

                              .step3_txt2 {
                                   font-size: 14px;
                                   font-weight: 400;
                                   color: #666666;
                                   margin-top: 20px;
                              }

                         }

                         .next {
                              margin-top: 20px;
                              width: 90px;
                              height: 34px;
                              background: $colorMain;
                              border-radius: 2px;
                              color: rgba(255, 255, 255, 1);
                              font-size: 14px;
                              line-height: 34px;
                              text-align: center;
                              cursor: pointer;
                         }

                    }

               }
          }

          .outer3 {
               z-index: 5;
               width: 100%;
               height: 1px;
               background: #f7f7f7;
               margin-top: 20px;
          }
     }
}

.outer1 {
     z-index: 6;
     height: 46px;
     border-radius: 4px 4px 0 0;
     background-color: rgba(248, 248, 248, 1);
     width: 100%;
     justify-content: center;
     align-items: center;
     padding: 0 20px;

     .bd2 {
          width: 100%;
          justify-content: space-between;

          .word1 {
               z-index: 24;
               width: 82px;
               height: 14px;
               display: block;
               overflow-wrap: break-word;
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               letter-spacing: -0.33764705061912537px;
               font-family: SourceHanSansCN-Medium;
               white-space: nowrap;
               line-height: 14px;
               text-align: center;
               margin-top: 1px;
          }

          .label1 {
               z-index: 25;
               width: 16px;
               height: 16px;
          }
     }
}

.outer5 {

     height: 34px;
     border-radius: 2px;
     border: 1px solid rgba(217, 217, 217, 1);
     background-color: rgba(255, 255, 255, 1);
     width: 90px;
     justify-content: center;
     align-items: center;
     cursor: pointer;
     margin-right: 20px;

     .word4 {

          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;

          line-height: 20px;
          text-align: center;
     }
}

.outer4 {
     z-index: auto;
     width: 100%;
     height: 34px;
     justify-content: center;
     margin: 15px 0
}

.outer6 {

     height: 34px;
     border-radius: 2px;
     background-color: $colorMain;
     width: 90px;
     justify-content: center;
     align-items: center;
     border: none;
     cursor: pointer;

     .word5 {

          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: -0.33764705061912537px;

          line-height: 20px;
          text-align: center;
     }
}
</style>
<style lang="scss">
.el-table__empty-block {
     display: none;
}

.con1 {
     /* background-color: #F9F9F9; */

     .el-dialog__header {
          display: none;
     }

     .el-dialog__body {
          padding: 0;
     }
}

.quick_order_main {
     table {
          width: 1200px !important;
     }
     .el-table__header {
    table-layout: auto;
    border-collapse: separate;
}

     .el-table thead tr {
          background-color: #F4F4F4;
     }

     .el-table th {
          background-color: #F4F4F4;
     }
}

.has-gutter {
     background: #F4F4F4 !important;
}

.step_swiper {
     .el-carousel__container {
          position: relative;
          height: 170px;
     }

     .el-carousel__indicators {
          display: none;
     }
}

.con1 {
     .noMatchList {
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;

          .noMatchList_tip {
               font-size: 14px;
               color: #999999;
               padding-left: 30px;
               margin-top: 20px;

               span:nth-child(2) {
                    font-size: 12px;
                    color: #666666;
                    margin-left: 5px;
               }

               .iconfont {
                    color: #666666;
                    font-size: 13px;
                    margin-left: 5px;
                    cursor: pointer;
               }
          }

          .noMatchList_list {
               position: relative;
               font-size: 13px;
               line-height: 18px;
               color: #333333;
               margin-top: 10px;
               padding-left: 30px;
               word-break: break-all;
               clear: both;

               div {
                    &.hideMore {
                         display: -webkit-box;
                         overflow: hidden;
                         -webkit-line-clamp: 2;
                         text-overflow: ellipsis;
                         -webkit-box-orient: vertical;
                    }
               }

               span {
                    margin-top: 3px;
                    margin-bottom: 6px;
                    float: right;
                    background: #F9F9F9;
                    cursor: pointer;

                    &:hover {
                         color: $colorMain;
                    }
               }
          }
     }
}

</style>